// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react"
import * as p from "@plasmicapp/react-web"
export type ScreenValue =
  | "mobileLandscape"
  | "mobilePortrait"
  | "ultraHd"
  | "hd"
  | "mobileSmall"
  | "tablet"
  | "ipadPro"
export const ScreenContext = React.createContext<ScreenValue[] | undefined>(
  "PLEASE_RENDER_INSIDE_PROVIDER" as any
)

/**
 *  @deprecated Plasmic now uses a custom hook for Screen variants, which is
 *  automatically included in your components. Please remove this provider
 *  from your code.
 */
export function ScreenVariantProvider(props: React.PropsWithChildren) {
  console.warn(
    "DEPRECATED: Plasmic now uses a custom hook for Screen variants, which is automatically included in your components. Please remove this provider from your code."
  )
  return props.children
}

export const useScreenVariants = p.createUseScreenVariants(true, {
  mobileLandscape: "(min-width:0px) and (max-width:768px)",
  mobilePortrait: "(max-width:479px)",
  ultraHd: "(max-width:1920px)",
  hd: "(max-width:1536px)",
  mobileSmall: "(max-width:360px)",
  tablet: "(max-width:990px)",
  ipadPro: "(max-width:834px)",
})

export default ScreenContext
/* prettier-ignore-end */
