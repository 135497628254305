// extracted by mini-css-extract-plugin
export var root = "PlasmicProducts-module--root--EKjhR";
export var desktopProducts = "PlasmicProducts-module--desktopProducts--3KF--";
export var background = "PlasmicProducts-module--background--2kKQq";
export var headerDarkV1 = "PlasmicProducts-module--headerDarkV1--1haYH";
export var group3 = "PlasmicProducts-module--group3--23ElP";
export var frame4 = "PlasmicProducts-module--frame4--1zClA";
export var svg__brUl = "PlasmicProducts-module--svg__brUl--17r4O";
export var svg___0ReDx = "PlasmicProducts-module--svg___0ReDx--1epqf";
export var svg__lesVk = "PlasmicProducts-module--svg__lesVk--3MjNc";
export var mLogoSvgImage2Vector2 = "PlasmicProducts-module--mLogoSvgImage2Vector2--20ULH";
export var svg___9SzM = "PlasmicProducts-module--svg___9SzM--xpcOo";
export var santedLogoSmallerJan20212 = "PlasmicProducts-module--santedLogoSmallerJan20212--xHGEx";
export var svg___4YkYe = "PlasmicProducts-module--svg___4YkYe--VJ1vm";
export var santedLogoSmallerJan20213 = "PlasmicProducts-module--santedLogoSmallerJan20213--1YB3Z";
export var navi = "PlasmicProducts-module--navi--1IuHR";
export var products = "PlasmicProducts-module--products--3ANg-";
export var freeBox__aI9Vc = "PlasmicProducts-module--freeBox__aI9Vc--2mP6E";
export var contact = "PlasmicProducts-module--contact--1GXdP";
export var freeBox__iPnyQ = "PlasmicProducts-module--freeBox__iPnyQ--3dS4I";
export var explore = "PlasmicProducts-module--explore--1erRC";
export var freeBox__jgYBd = "PlasmicProducts-module--freeBox__jgYBd--irpbl";
export var svg__zSfrJ = "PlasmicProducts-module--svg__zSfrJ--3JNum";
export var headerLight = "PlasmicProducts-module--headerLight--3UFp-";
export var group5 = "PlasmicProducts-module--group5--A5O7W";
export var cart = "PlasmicProducts-module--cart--zACgM";
export var img = "PlasmicProducts-module--img--2M603";
export var image2VectorBoxOpen1 = "PlasmicProducts-module--image2VectorBoxOpen1--q85h_";
export var svg__inn47 = "PlasmicProducts-module--svg__inn47--2ABwd";
export var navi2 = "PlasmicProducts-module--navi2--3zDEY";
export var products2 = "PlasmicProducts-module--products2--38Ttr";
export var freeBox___0ZGl = "PlasmicProducts-module--freeBox___0ZGl--3u5bb";
export var explore2 = "PlasmicProducts-module--explore2--3sM7k";
export var freeBox__d8OrM = "PlasmicProducts-module--freeBox__d8OrM--24ycT";
export var inquires = "PlasmicProducts-module--inquires--3wiD-";
export var freeBox__vjmI4 = "PlasmicProducts-module--freeBox__vjmI4--2v-4q";
export var svg__eetXg = "PlasmicProducts-module--svg__eetXg--2qZnj";
export var mdlViewer = "PlasmicProducts-module--mdlViewer--3gktw";