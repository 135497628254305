// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type CloseIconHMenusvgIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function CloseIconHMenusvgIcon(props: CloseIconHMenusvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 400 407.907"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M20.794 25.586l-6.881 6.878 40.369 39.23a391025.56 391025.56 0 0157.353 55.745c30.766 29.917 47.506 46.181 59.528 57.837 6.523 6.325 11.913 11.652 11.979 11.839.065.186-37.935 38.381-84.445 84.877L14.134 366.53l6.839 6.783c3.761 3.731 7.016 6.759 7.235 6.729.218-.03 23.629-23.233 52.025-51.561a217200.54 217200.54 0 0184.659-84.413l33.032-32.907 46.503 45.183a92472.566 92472.566 0 0162.085 60.366c8.569 8.35 26.798 26.095 40.507 39.433l24.925 24.25 7.081-6.876 7.08-6.876-25.495-24.838a52036.588 52036.588 0 00-50.377-49.003c-38.595-37.479-57.629-55.992-66.745-64.916a6619.683 6619.683 0 00-19.767-19.235c-6.268-6.071-11.395-11.227-11.395-11.456 0-.23 30.028-30.512 66.729-67.294 36.702-36.782 73.537-73.73 81.856-82.107l15.127-15.231-6.969-6.981-6.97-6.981-82.332 82.332-82.333 82.332-46.043-44.709c-25.323-24.59-49.286-47.877-53.251-51.75-21.927-21.418-70.19-68.192-70.317-68.148-.082.028-3.245 3.147-7.029 6.93"
        }
        fillRule={"evenodd"}
      ></path>
    </svg>
  )
}

export default CloseIconHMenusvgIcon
/* prettier-ignore-end */
