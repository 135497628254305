// extracted by mini-css-extract-plugin
export var root = "PlasmicHeaderLight-module--root--3hZP5";
export var freeBox = "PlasmicHeaderLight-module--freeBox--2IX8r";
export var group6 = "PlasmicHeaderLight-module--group6--3lOdh";
export var svg__vOqlb = "PlasmicHeaderLight-module--svg__vOqlb--3AnSr";
export var navigation = "PlasmicHeaderLight-module--navigation--5I7IN";
export var products = "PlasmicHeaderLight-module--products--CGZJe";
export var link__iJjts = "PlasmicHeaderLight-module--link__iJjts--189Tm";
export var explore = "PlasmicHeaderLight-module--explore--1VgfR";
export var link__j4YOm = "PlasmicHeaderLight-module--link__j4YOm--sUIq_";
export var inquires = "PlasmicHeaderLight-module--inquires--3xt5J";
export var link__uxmYe = "PlasmicHeaderLight-module--link__uxmYe--3u3aP";
export var cart2 = "PlasmicHeaderLight-module--cart2--TsnFR";
export var img = "PlasmicHeaderLight-module--img--1zPmy";
export var image2VectorBoxOpen2 = "PlasmicHeaderLight-module--image2VectorBoxOpen2--20v8L";
export var svg__gPzLs = "PlasmicHeaderLight-module--svg__gPzLs--3Caqc";
export var svg__ylSj = "PlasmicHeaderLight-module--svg__ylSj--9DaJ2";
export var rectangle1 = "PlasmicHeaderLight-module--rectangle1--JYvx1";