// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type CloseIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function CloseIconsvgIcon(props: CloseIconsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 400 400"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M102.336 102.742l-6.613 6.648 45.3 45.305 45.3 45.305-45.306 45.311-45.306 45.311 6.834 6.833 6.833 6.834 45.311-45.306L200 213.677l45.311 45.306 45.311 45.306 6.833-6.834 6.834-6.833-45.306-45.311L213.677 200l45.306-45.311 45.306-45.311-6.834-6.833-6.833-6.834-45.311 45.306L200 186.323l-45.12-45.115c-24.816-24.813-45.302-45.114-45.525-45.114-.223 0-3.381 2.991-7.019 6.648"
        }
        fillRule={"evenodd"}
      ></path>
    </svg>
  )
}

export default CloseIconsvgIcon
/* prettier-ignore-end */
