// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type Svgg2IconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function Svgg2Icon(props: Svgg2IconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 23 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6.2.206C5.3.48 4.844.83 2.853 2.781-.103 5.677.072 5.14.007 11.527c-.082 8.022.532 8.976 5.892 9.153l2.45.08v-1.8L5.9 18.9c-4.129-.098-4.212-.227-4.29-6.641-.073-6.072-.364-5.45 2.555-5.45h2.403v2.675h4.1V6.81h5.348v2.317h1.604V6.536l1.61-1.6c2.038-2.027 1.95-2.188 1.953 3.524l.002 4.59h1.604V8.23c0-5.916-.257-6.692-2.583-7.775C19.281.026 7.49-.184 6.2.206zm3.301 2.015c-.286.319-1.04 1.12-1.677 1.782L6.666 5.207H2.921l1.577-1.585C6.27 1.842 6.596 1.686 8.606 1.66l1.415-.018-.52.579zm9.89-.306c.126.127-.303.67-1.382 1.743l-1.566 1.559-2.533-.05-2.534-.05 1.637-1.755 1.637-1.755 2.278.062c1.253.034 2.36.145 2.462.246zm-3.376 13.096v2.139h-4.1v1.604h4.1v4.279h1.604v-4.279h4.278V17.15H17.62v-4.278h-1.604v2.139z"
        }
        fill={"currentColor"}
        fillOpacity={".7"}
      ></path>
    </svg>
  )
}

export default Svgg2Icon
/* prettier-ignore-end */
