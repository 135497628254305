// extracted by mini-css-extract-plugin
export var root = "PlasmicInquiryEForm-module--root--2r2jO";
export var freeBox__l67S9 = "PlasmicInquiryEForm-module--freeBox__l67S9--g7QK4";
export var freeBox__neAfG = "PlasmicInquiryEForm-module--freeBox__neAfG--1-NZZ";
export var freeBox__eqlbI = "PlasmicInquiryEForm-module--freeBox__eqlbI--2GtPJ";
export var textbox__dwi9Z = "PlasmicInquiryEForm-module--textbox__dwi9Z--RJaVb";
export var freeBox__tVhTl = "PlasmicInquiryEForm-module--freeBox__tVhTl--vRvJD";
export var freeBox__cJvBt = "PlasmicInquiryEForm-module--freeBox__cJvBt--1VC_i";
export var textbox__ii2A9 = "PlasmicInquiryEForm-module--textbox__ii2A9--3yILT";
export var freeBox__hwM5U = "PlasmicInquiryEForm-module--freeBox__hwM5U--CUy9B";
export var freeBox__xuKIm = "PlasmicInquiryEForm-module--freeBox__xuKIm--Dgj0N";
export var textbox__w6JYt = "PlasmicInquiryEForm-module--textbox__w6JYt--1FnzB";
export var freeBox__kuJgi = "PlasmicInquiryEForm-module--freeBox__kuJgi--2hy1J";
export var freeBox__wEkpw = "PlasmicInquiryEForm-module--freeBox__wEkpw--39IlU";
export var textarea = "PlasmicInquiryEForm-module--textarea--1aib1";
export var button = "PlasmicInquiryEForm-module--button--1Vq4g";